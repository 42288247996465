<template>
  <b-card>
      <h3>Juridico del ChatBot</h3>
      <p>Aquí puedes gestionar las Juridico del ChatBot.</p>
  </b-card>

</template>

<script>
export default {
  name: 'Juridico',
}
</script>